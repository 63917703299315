<!-- 我的任务-我的活动模块 [学生 / 老师] -->
<template>
    <div class="center-situation">
        <div class="activity-list wrapper">
            <proTitle></proTitle>
            <div class="switch flex-align-between">
                <div>
                    <div class="switch-btn-warpper" :class="{'active':activeSwitch == item.id}"
                        v-for="(item,index) in switchList" :key="index" @click="switchType(item.id)">
                        <button class="csp switch-btn">
                            {{item.name}}
                        </button>
                    </div>
                </div>
                <div class="add csp" v-if="activeSwitch == 1">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span @click="$router.push(`/activity/add?breadNumber=4`)">创建活动</span>
                </div>
            </div>
            <!-- 条件筛选 -->
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-form-item label="">
                    <el-date-picker size="mini" v-model="formInline.datetimerange" type="datetimerange"
                        start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" prefix-icon="el-icon-date"
                        @change="filterCourse()" value-format="yyyy-MM-dd HH:mm:ss" :editable="false"
                        class="date-picker">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="活动类型 :">
                    <el-select v-model="formInline.activityType" placeholder="全部" @change="filterCourse()">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="大赛类" :value="0"></el-option>
                        <el-option label="教研类" :value="1"></el-option>
                        <el-option label="合作学习墙类" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动状态 :">
                    <el-select v-model="formInline.status" placeholder="全部" @change="filterCourse()">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未开始" :value="0"></el-option>
                        <el-option label="进行中" :value="1"></el-option>
                        <el-option label="已结束" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label>
          <el-input v-model="formInline.search" @input="getActivityList()" size="mini" placeholder="输入活动名称"
            suffix-icon="el-icon-search"></el-input>
        </el-form-item> -->
            </el-form>
            <!-- 旧版活动列表 备份勿删-->
            <!-- <cardList :cardType="'Activity'" :dataList="activityList" :total="pages.total"
        :proid="String($store.state.user.currentProject.id)" @returnPagesData="handlePagesData"></cardList> -->
            <!-- 旧版活动列表 备份勿删-->

            <div class="card-list-wrapper">
                <div class="card-list" v-if="dataList.length > 0">
                    <ul class="flex-layout card-ul">
                        <li v-for="(item, index) in dataList" :key="item.id" class="card csp"
                            @click.prevent="toDetail(item,index)">
                            <div class="status flex-align-between">
                                <!-- 我的活动 -->
                                <div class="kind-type"
                                    :class="item.activityType === 1? 'background-color-1' : item.activityType === 0?'':'background-color-2'">
                                    {{item.activityType === 1? '教研类' :item.activityType === 0?'大赛类':'合作学习墙'}}</div>
                                <!-- 右上角标签 -->
                                <div class="kind">
                                    <img class="course-type-image" :src="acitvityTypeImg[item.status]" alt="" />
                                </div>
                            </div>
                            <div class="img-wrapper">
                                <img :src="downloadURL + item.cover"
                                    :onerror="$store.state.course[`defaultActivityCoverStudyWall`]" alt
                                    class="cover-img" />
                                <p class="default-tilel" v-if="!item.cover">主题活动</p>
                            </div>
                            <div class="flex-column-between info">
                                <h3 class="title wes-2">{{item.name}}</h3>
                                <!-- 我的活动 -->
                                <div>
                                    <p class="time">时间：{{dayjs(item.activityBeginTime).format('YYYY-MM-DD HH:mm')}} --
                                        {{dayjs(item.activityEndTime).format('YYYY-MM-DD HH:mm')}}</p>
                                    <!-- 暂时隐藏 -->
                                    <!-- <p class="time">{{0}}人参与</p> -->
                                    <!-- 下方P标签为占位标签，上方P标签隐藏打开后可删除 -->
                                    <p class="time"></p>
                                </div>
                            </div>
                            <div class="btns-more">
                                <el-dropdown trigger="hover" class="csp" placement="top-start" @click.stop>
                                    <i class="el-icon-more"></i>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            @click.native="$router.push(`/activity/detail/${item.id}?breadNumber=4`)">查看
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            @click.native="$router.push(`/activity/edit/${item.id}?breadNumber=4`)"
                                            :disabled="item.status == 2" v-if="activeSwitch == 1">编辑
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteCourse(item.id)"
                                            :disabled="item.status == 2" v-if="activeSwitch == 1">删除
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            @click.native="$router.push(`/activity/child/detail/${item.id}?breadNumber=4`)"
                                            v-if="activeSwitch == 1 && item.activityType == 2">子活动
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="item.activityType == 2"
                                            @click.native="getGoYzTotalCollectionUrl(item.id)">
                                            作品集
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </li>
                        <!-- <li class="placeholder-card card"></li> -->
                    </ul>
                    <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" :pageSizes="[3,6,9]" @pagination="handlePagesData">
                    </pagination>
                </div>
                <!-- 无课程 -->
                <Empty :show="dataList && dataList.length === 0"></Empty>
            </div>
            <!-- 填写身份证弹窗 -->
            <el-dialog title="请完善个人用户信息" :visible.sync="dialogVisible" width="30%" custom-class="myDialog">
                <div class="idCode flex-align">
                    身份证号:
                    <el-input v-model="input" style="width:80%" placeholder="请输入身份证号码"></el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="idCodeChange">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    export default {
        data() {
            return {
                formInline: { //查询项绑定value
                    status: "", //状态查询
                    search: "", //名称查询,
                    datetimerange: "",
                },
                activityList: [], //活动列表
                activeSwitch: 0,
                pages: { //分页
                    currentPage: 1,
                    pageSize: 6,
                    total: 100,
                },
                dataList: [],
                acitvityTypeImg: { //活动、作业 右上角图标 对照表
                    // 1:'未开始',
                    // 2:'进行中',
                    // 3:'已结束'
                    0: require("@/assets/images/course/notstart.png"),
                    1: require("@/assets/images/course/start.png"),
                    2: require("@/assets/images/course/end.png")
                },
                switchList: [],
                dialogVisible: false,
                input: '',
                userInfo: this.$store.state.user.userInfo
            };
        },
        watch: {
            'currentProject.id': {
                handler(newVal, oldVal) {
                    if (!newVal) return;
                    this.pages.currentPage = 1;
                    this.getActivityList();
                }
            },
            'role': {
                handler(newVal, oldVal) {
                    console.log('role', newVal)
                    console.log('roleOLd', oldVal)
                    if (!newVal) return;
                    this.pages.currentPage = 1;
                    this.switchListByRole();
                    this.activeSwitch = 1;
                    this.$store.commit('getActivityType', this.activeSwitch);
                    this.getActivityList();
                }
            }
        },
        computed: {
            ...mapState({
                role: state => state.user.role,
                currentActivityType: state => state.course.currentActivityType
            })
        },
        beforeRouteEnter(to, from, next) {
            console.log(from);
            next(vm => {
                if (from.name == 'activityEdit' || (from.matched.length > 2 && from
                        .matched[1].name == 'activityAdd')) {
                    vm.getActivityList();
                } else if (from.name == "ActivityStageDetail" || from.name == 'NewActive') {
                    vm.activeSwitch = vm.currentActivityType;
                } else {
                    vm.activeSwitch = 0;
                    vm.$store.commit('getActivityType', vm.activeSwitch);
                    vm.getActivityList();
                }
            });
        },
        methods: {
            async getUserinfo() {
                let resData = await this.$Api.Home.userInfo();
                this.$store.commit('getUserInfo', resData.data);
                this.userInfo = resData.data;
                console.log('this.userinfo:', this.userinfo)
            },
            // 校验身份证
            idCodeChange() {
                console.log(this.input);
                let reg =
                    /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
                if (!reg.test(this.input)) {
                    this.$message.error('请输入正确的身份证号码')
                } else {
                    console.log('校验身份证通过');
                    let data = {
                        id: this.userInfo.id,
                        idCard: this.input
                    }
                    this.$Api.My.getfixinfo(data)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.getUserinfo();
                                this.dialogVisible = false;
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        })
                        .catch(err => {
                            console.log('校验失败', err);
                        })
                }
            },
            handlePagesData(data) {
                console.log('handlePagesData', data)
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getActivityList();
            },
            // 根据身份判断切换列表
            switchListByRole() {
                this.switchList = [];
                let list = [{
                    name: '我创建的',
                    id: 1
                }, {
                    name: '我参与的',
                    id: 0
                }, {
                    name: '作品评选',
                    id: 2
                }]
                // 非老师师范生身份,只取"我参与的"
                this.switchList = [list[1]];
                // 老师和师范生获取全部列表
                if (this.role == 'teacher' || this.role == 'normal') {
                    this.switchList = list;
                    // this.activeSwitch = 0;
                    // this.$store.commit('getActivityType', this.activeSwitch);
                    // this.switchList = list.slice(1,3); //003项目隐藏我创建的
                }
                // this.activeSwitch = 1;
                // this.$store.commit('getActivityType', this.activeSwitch);
            },

            // 去详情页
            toDetail(item, index) {
                if (this.activeSwitch == 2) {
                    // 跳去子活动作品评选
                    this.$router.push({
                        // path: `/activity/stage/detail/${item.id}`,
                        path: `/myActivity/selection/${item.id}`,
                        query: {
                            breadNumber: 4,
                        }
                    });
                    return;
                }

                // 判断是否是003项目
                if (this.$store.state.user.currentProject.id == 144) {
                    // 判断是否填写身份证信息
                    if (this.userInfo.idCard && this.userInfo.idCard.trim()) {
                        console.log('有身份证');
                        let pathMap = {
                            0: `/newActiveDetail/${item.id}`,
                            1: `/activity/child/detail/${item.id}`,
                            2: `/activity/child/detail/${item.id}`,
                        }
                        this.$router.push({
                            path: pathMap[item.activityType],
                            query: {
                                breadNumber: 4,
                            }
                        });
                    } else {
                        console.log('无身份证，弹窗');
                        this.dialogVisible = true;
                        return
                    }
                }

                console.log('检查身份信息', this.$store.state.user.currentProject.id);
                let pathMap = {
                    0: `/newActiveDetail/${item.id}`,
                    1: `/activity/child/detail/${item.id}`,
                    2: `/activity/child/detail/${item.id}`,
                }
                this.$router.push({
                    path: pathMap[item.activityType],
                    query: {
                        breadNumber: 4,
                    }
                });

            },
            switchType(id) {
                this.activeSwitch = id;
                this.$store.commit('getActivityType', this.activeSwitch);
                Vue.set(this.formInline, 'datetimerange', '');
                Vue.set(this.formInline, 'activityType', '');
                Vue.set(this.formInline, 'status', '');
                this.pages.currentPage = 1;
                this.getActivityList();
            },
            filterCourse() {
                this.pages.currentPage = 1;
                this.getActivityList();
            },
            toYzCollection() {
                if (!this.yzCollectionUrl) return;
                window.open(this.yzCollectionUrl);
            },
            async getGoYzTotalCollectionUrl(id) {
                console.log(id);
                let params = {
                    activityId: id,
                    type: this.activeSwitch // 0 参与 1 创建
                }
                let resData = await this.$Api.Activity.getGoYzTotalCollectionUrl(params);
                // console.log(resData);
                this.yzCollectionUrl = resData.data
                this.toYzCollection();
            },
            // 获取活动列表
            async getActivityList() {
                let params = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId: this.$store.state.user.currentProject.id,
                    activityType: this.formInline.activityType,
                    status: this.formInline.status,
                    activityBeginTime: this.formInline.datetimerange ? this.formInline.datetimerange[0] : '',
                    activityEndTime: this.formInline.datetimerange ? this.formInline.datetimerange[1] : '',
                }
                let resData
                if (this.activeSwitch == 1) {
                    // 我创建的
                    resData = await this.$Api.Activity.getActivityList(params);
                }

                if (this.activeSwitch == 0) {
                    // 我参与的
                    resData = await this.$Api.Activity.getMyActivityList(params);
                }

                if (this.activeSwitch == 2) {
                    // 作品评选
                    resData = await this.$Api.Activity.getMyEvaluationActivity(params);
                }
                // console.log(resData.data)
                this.dataList = resData.data.list;
                this.pages.total = resData.data.totalCount
            },
            // 删除课程
            deleteCourse(id) {
                // console.log(id);
                this.$confirm('是否删除该活动?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // let resData = this.$Api.Course.commentDel(item.id)
                    // this.$Api.Course.activityDel(id).then((resData) => {
                    this.$Api.Activity.delActivity(id).then((resData) => {
                        // console.log(resData)
                        if (resData.code === 200 && resData.data == 1) {
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                            this.getActivityList();
                        } else {
                            this.$message.error(resData.msg);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
        },
        mounted() {
            this.switchListByRole();
            this.getActivityList();
        },
    };
</script>

<style lang="less" scoped>
    @import "./less/course.less";

    @font-face {
        font-family: 'ktf';
        src: url("../../../static/font/ktf.TTF");
    }

    .activity-list {
        .switch {
            margin-bottom: 10px;

            .switch-btn-warpper {
                height: 25px;
                display: inline-block;
                margin-right: 20px;

            }

            .switch-btn {
                padding: 0 28px;
                font-size: 12px;
                line-height: 25px;
                background-color: #F8F8F8;
                color: #999999;
                border-radius: 10px;
            }

            .active {
                .switch-btn {
                    background-color: #508EF9;
                    color: #fff;
                }
            }

            .add {
                font-size: 12px;
                color: #999999;

                .el-icon-circle-plus-outline {
                    margin-right: 10px;
                    font-size: 16px;
                    vertical-align: text-bottom;
                }

                &:hover {
                    color: rgba(153, 153, 153, 0.6);
                }
            }

            @media screen and (min-width: 1250px) {
                .switch-btn-warpper {
                    height: 30px;
                }

                .switch-btn {
                    padding: 0 34px;
                    font-size: 15px;
                    line-height: 30px;
                }

                .add {
                    font-size: 16px;

                    .el-icon-circle-plus-outline {
                        font-size: 19px;
                    }
                }
            }

        }

        .form-inline {
            text-align: left;
        }

        .card-list-wrapper {
            .card-ul {
                flex-flow: row wrap;

                .card {
                    position: relative;
                    box-sizing: border-box;
                    margin: 0 0px 20px 0;
                    border-radius: 10px;
                    padding: 0 0 10px 0;
                    width: 220px;
                    border: 1px solid #e8e8e8;
                    margin-right: 10px;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                //li上部分
                .status {
                    box-sizing: border-box;

                    .kind {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 999;

                        .course-type-image {
                            width: 50px;
                        }
                    }

                    .kind-type {
                        position: absolute;
                        top: 14px;
                        left: 0;
                        z-index: 999;

                        text-align: center;
                        line-height: 20px;
                        font-size: 12px;
                        padding: 2px 12px 2px 7px;
                        height: 24px;
                        border-radius: 2px 12px 12px 2px;
                        color: #ffffff;
                        background: #F6B926;
                    }

                    .background-color-1 {
                        background: #508EF9;
                    }

                    .background-color-2 {
                        background: #EF5757;
                    }
                }

                //中间图片
                .img-wrapper {
                    width: 100%;
                    height: 128px;
                    margin: 0px auto 5px;
                    overflow: hidden;
                    border-radius: 3px;
                    position: relative;

                    .cover-img {
                        height: 100%;
                    }

                    img {
                        width: 100%;
                        max-width: 100%;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }

                    .default-tilel {
                        font-family: 'ktf';
                        position: absolute;
                        color: #fff;
                        font-size: 22px;
                        top: 34%;
                        left: 25px;
                    }
                }


                //li下部分
                h3.title {
                    margin-bottom: 5px;
                    height: 38px;
                    font-size: 13px;
                    padding: 0 11px;
                    font-weight: bold;
                }

                .time {
                    padding-bottom: 3px;
                    color: #595959;
                    font-size: 12px;
                    padding: 0 11px;
                    line-height: 20px;
                    min-height: 20px;
                }

                .btns-more {
                    position: absolute;
                    bottom: 5px;
                    right: 15px;

                    .el-icon-more {
                        font-size: 22px;
                        color: #C9C9C9;
                    }
                }
            }

            @media screen and (min-width: 1250px) {
                .card-ul {
                    .card {
                        margin: 0 0 24px 0;
                        padding: 0 0 15px 0;
                        width: 266px;
                        margin-right: 15px;
                    }

                    .img-wrapper {
                        height: 155px;
                        margin: 0px auto 10px;

                        .default-tilel {
                            font-size: 28px;
                            top: 33%;
                        }
                    }

                    h3.title {
                        margin-bottom: 10px;
                        font-size: 15px;
                        height: 44px;
                    }
                }
            }

        }

        .myDialog {
            .idCode {
                justify-content: space-around;
            }
        }
    }
</style>